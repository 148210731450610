
  /**
   * Branch: remotes/origin/develop
   * Last commit: 
   *
   * commit 00b45bf54e9b358f120b162493a57317c4026b7f
   * Merge: d141cb5 997d61b
   * Author: Jordi Romagos Figueras <jordi.romagos@dxc.com>
   * Date:   Mon Dec 9 10:34:49 2024 +0100
   *     Merge pull request #28 from JCCM/literales
   *     
   *     literales
   */
   
   
  export const version = '1.0.0-CEGE-build.9-12-2024 10:37:51';

